<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>DIRECTORY</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.addModal.show()" text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>
        <custom-vue-table-card class="d-sm-none-lg-block" :show-search-box="false" title="" :fields="fields"
                               :url="listUrl" :per-page="10"
                               ref="table" :extra-params="{ext_search}">

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table-card>

        <modal title="Add Directory" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
            <add-directory @success="formSuccess"/>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the directory <b v-html="deletingItem && deletingItem.name"></b>. Are you
                sure ?</p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
        <directory-listing-mobile-view :ext_search="ext_search" @viewDetails="viewDetails" @setDelete="setDelete"
                                       class="d-lg-none" ref="directoryMobileView"></directory-listing-mobile-view>
    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddDirectory from './AddDirectory';
import DirectoryListingMobileView from '../../../../views/cms/contact-us/directory/DirectoryListingMobileView';

export default {
    name       : 'Directory',
    components : { DirectoryListingMobileView, AddDirectory },
    data () {
        return {
            ext_search   : '',
            listUrl      : urls.cms.directory.list,
            deleteUrl    : urls.cms.directory.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name  : 'name',
                    title : 'Name'
                },
                // {
                //     name: '__slot:is_team_member',
                //     title: 'Whether Team Member'
                // },
                // {
                //     name: 'team_member.name',
                //     title: 'Team Member'
                // },
                {
                    name  : 'email_id',
                    title : 'E-Mail'
                },
                {
                    name  : 'contact_number',
                    title : 'Contact Number'
                },
                {
                    name  : 'mobile_number',
                    title : 'Mobile Number'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewDetails (id) {
            this.$router.push('/cms/directory/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
            refs.directoryMobileView.loadData();

            this.editingItem = null;
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            this.$refs.table.refreshTable();
            this.$refs.directoryMobileView.loadData();
        }
    }
};
</script>

<style scoped>

</style>
