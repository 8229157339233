<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{is_team_member : false}">
        <div class="row">
            <div class="col-6">
                <validated-input label="Name*" v-model="model.name"
                                 :rules="{required: true, max:30}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="email" label="Email ID*" name="Email id" v-model="model.email_id"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Contact Number" v-model="model.contact_number"
                                 :rules="{required: false, min:10, max:10}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Mobile Number" v-model="model.mobile_number"
                                 :rules="{required: false, min:10, max:10}" :disabled="loading"/>
            </div>
            <!--            <div class="col-6">-->
            <!--                <validated-checkbox label="Is Team Member" v-model="model.is_team_member"-->
            <!--                                    :rules="{required: false}" :disabled="loading"/>-->
            <!--            </div>-->
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <!--            <div class="col-6" v-if="model.is_team_member">-->
            <!--                <validated-ajax-vue-select :url="teamSelectOptions" label="Team member"-->
            <!--                                           v-model="model.team_member" :rules="{required: false}"-->
            <!--                                           :disabled="loading"/>-->
            <!--            </div>-->
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'AddDirectory',

    data () {
        return {
            addUrl            : urls.cms.directory.addEdit,
            teamSelectOptions : urls.cms.office.teamSelect
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Directory..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
